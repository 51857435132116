/*
Copyright 2023 Victor Ma

This file is part of Mimid.

Mimid is free software: you can redistribute it and/or modify it under the terms
of the GNU Affero General Public License as published by the Free Software
Foundation, either version 3 of the License, or (at your option) any later
version.

Mimid is distributed in the hope that it will be useful, but WITHOUT ANY
WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR A
PARTICULAR PURPOSE. See the GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License along
with Mimid. If not, see <https://www.gnu.org/licenses/>.
*/

@use "../breakpoints.scss";

.ThemeSetting {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.ThemeSettingLabel {
  flex-grow: 1;
}

@mixin set-breakpoint-spacing($breakpoint, $spacing) {
  @media (min-width: $breakpoint) {
    .ThemeSetting {
      gap: $spacing;
    }
  }
}

@include set-breakpoint-spacing(
  breakpoints.$xs-breakpoint,
  breakpoints.$xs-spacing
);
@include set-breakpoint-spacing(
  breakpoints.$sm-breakpoint,
  breakpoints.$sm-spacing
);
@include set-breakpoint-spacing(
  breakpoints.$md-breakpoint,
  breakpoints.$md-spacing
);
@include set-breakpoint-spacing(
  breakpoints.$lg-breakpoint,
  breakpoints.$lg-spacing
);
@include set-breakpoint-spacing(
  breakpoints.$xl-breakpoint,
  breakpoints.$xl-spacing
);
