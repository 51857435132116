/*
Copyright 2023 Victor Ma

This file is part of Mimid.

Mimid is free software: you can redistribute it and/or modify it under the terms
of the GNU Affero General Public License as published by the Free Software
Foundation, either version 3 of the License, or (at your option) any later
version.

Mimid is distributed in the hope that it will be useful, but WITHOUT ANY
WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR A
PARTICULAR PURPOSE. See the GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License along
with Mimid. If not, see <https://www.gnu.org/licenses/>.
*/

$xs-breakpoint: 0px;
$sm-breakpoint: 600px;
$md-breakpoint: 900px;
$lg-breakpoint: 1200px;
$xl-breakpoint: 1536px;

$xs-spacing: 4vw;
$sm-spacing: 2vw;
$md-spacing: 1vw;
$lg-spacing: 1vw;
$xl-spacing: 1vw;
